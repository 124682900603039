<template>
	<div id="scene" class="scene">
		<div class="content-wrap1" data-depth="0.40">
			<img class="station6" src="~@/assets/images/index/station.png" />
		</div>
		
		<div class="content-wrap2" data-depth="0.20">
			<img class="mountain6" src="~@/assets/images/index/mountain.png" alt="">
		</div>
		
		<div class="content-wrap3" data-depth="0.60">
			<img class="astronaut6" src="~@/assets/images/index/astronaut.png" />
		</div>
		
		<div class="content-wrap4" data-depth="1.00">
			<div class="text-touch-notion">
				<p class="text-touch-notion1">GET IN&nbsp;</p>
				<p class="text-touch-notion2">TOUCH</p>
			</div>
			<div class="text-touch-detail">
				<p>Have a new project in mind? Need help with an ongoing one?</p>
				<p>Drop us a line about your project needs, we answer same day.</p>
			</div>
			<div class="input1">
				<input class="input-item input-full-name" v-model="fullNameStr" type="text"
					placeholder="" />
				<input class="input-item input-email" v-model="emailStr" type="text" placeholder="" />
				<input class="input-item input-tell-us" v-model="tellUsStr" type="text"
					placeholder="" />
			</div>
			
			<div class="submit">SUBMIT</div>
		</div>
	</div>
</template>

<script>
	import Parallax from 'parallax-js'

	export default {
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
			}
		},
		mounted() {
			var scene = document.getElementById('scene');
			var parallaxInstance = new Parallax(scene);
			parallaxInstance.friction(0.2, 0.2);
		},
		methods: {},
	}
</script>

<style scoped>
	.scene {
		width: 100%;
		height: 100%;
		position: relative;
		
		background-color: #000;
		color: #fff;
	}
	
	.content-wrap1, .content-wrap2, .content-wrap3, .content-wrap4 {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.station6 {
		position: absolute;
		width: 56%;
		right: -10%;
		top: 10%;
	
		object-fit: contain;
		
		opacity: 0.7;
	}
	
	.mountain6 {
		width: 100%;
		object-fit: contain;
		
		position: absolute;
		left: 0%;
		bottom: 0%;
		
		transform: rotateY(180deg);
	}
	
	.astronaut6 {
		position: absolute;
		width: 19%;
		right: 2%;
		top: 22.6%;
		
		object-fit: contain;
	
		z-index: 99;
	}
	
	.text-touch-notion {
		position: absolute;
		width: 80%;
		top: 10.2%;
		left: 16.25%;
		
		text-align: center;
		
		font-family: 'lovelo_black-webfont';
		font-size: 90px;
		
		z-index: 99;
		
		display: flex;
	}
	.text-touch-notion1 {
		color: #0096BA;
	}
	.text-touch-notion2 {
		color: #D25CAE;
	}
	
	.text-touch-detail {
		position: absolute;
		width: 78.8%;
		top: 23.6%;
		left: 16.25%;
		
		
		font-size: 28px;
		color: #fff;
		
		z-index: 99;
	}
	
	.text-touch-detail p {
		height: 40px;
		line-height: 40px;
	}
	
	.input1,
	.input2 {
		position: absolute;
		width: 25.5%;
		left: 16.25%;
	
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	
		z-index: 99;
	}
	
	.input1 {
		top: 33%;
	}
	
	.input2 {
		top: 33.9%;
	}
	
	.input-item {
		width: 100%;
		height: 50px;
	
		margin-top: 30px;
	
		background: rgba(0, 0, 0, 0);
		border-color: transparent;
	
		color: #fff;
		font-size: 14px;
		border-bottom: solid 1px rgba(255, 255, 255, 0.7);
	}
	
	.submit {
		position: absolute;
		top: 61.4%;
		left: 16.25%;
		width: 7%;
		height: 33px;
	
		background-color: #CBB17B;
		border-radius: 16px;
	
		display: flex;
		justify-content: center;
		align-items: center;
	
		font-size: 15px;
		color: #fff;
		text-shadow: 1px 3px 6px #875F15;
	
		z-index: 99;
	}
	
	@media only screen and (max-width: 1400px) {
		.text-touch-notion {
			font-size: 64px;
		}
		.text-touch-detail {
			font-size: 20px;
		}
		
		.text-touch-detail p {
			height: 28px;
			line-height: 28px;
		}
		
		.input-item {
			height: 36px;
		
			margin-top: 21px;
		
			font-size: 12px;
		}
		
		.submit {
			height: 24px;
			border-radius: 12px;
			font-size: 12px;
		}
	}
	
	@media only screen and (max-width: 800px) {
		.text-touch-notion {
			font-size: 32px;
		}
		.text-touch-detail {
			font-size: 12px;
		}
		
		.text-touch-detail p {
			height: 14px;
			line-height: 14px;
		}
		
		.input-item {
			height: 18px;
		
			margin-top: 11px;
		
			font-size: 12px;
		}
		
		.submit {
			height: 24px;
			border-radius: 12px;
			font-size: 12px;
		}
	}
</style>
